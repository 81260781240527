<template>
  <div class="loading-container"
       :style="{zIndex:top?99999:90,background:showCover?'rgba(236, 236, 236, 0.8)':'transparent'}">
    <div class="loading-box">
      <span class="loader" v-if="type=== 'loading'"></span>
      <img class="success-icon" v-if="type=== 'success'" src="./media/arrow.svg" alt=""/>
      <img class="success-icon" v-if="type=== 'error'" src="./media/fail.svg" alt=""/>
      <img class="success-icon" v-if="type=== 'warning'" src="./media/warning.svg" alt=""/>
      <div class="loading-text" :style="{color:textColorMap[type ?? 'loading']}">{{ label }}
        <span class="dot-loader" v-if="type=== 'loading'"></span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import 'tdesign-mobile-vue/es/style/index.css';
import {ref} from "vue";

const textColorMap = ref<Record<string, string>>({
  'success': "#2ba471",
  'error': "#d91010",
  'warning': "#ffae00",
  'loading': "#E25050",
});

const props = defineProps<{
  type?: 'success' | 'error' | 'warning' | 'loading',
  label: string,
  top: boolean,
  showCover: boolean
}>();
</script>


<style scoped>
.loading-container {
  z-index: 90;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.loading-box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  z-index: 9999;
  top: 30%;
  left: calc(50% - 70px);
  position: absolute;
  width: 140px;
  height: 140px;
  background: #ffffff;
  border-radius: 26px;
  box-shadow: 4px 4px 8px rgba(243, 243, 243, 0.1);
}


.loading-text {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  text-align: center;
  color: var(--primary-alert-color);
  line-height: 15px;
  height: 15px;
  font-size: 14px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
}

.success-icon {
  width: 55px;
  height: 55px;
}

.loader {
  margin-top: 20px;
  width: 48px;
  height: 48px;
  border: 5px solid var(--primary-alert-color);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  animation: pulse 1s linear infinite;
}

.loader:after {
  content: '';
  position: absolute;
  width: 48px;
  height: 48px;
  border: 5px solid var(--primary-alert-color);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: scaleUp 1s linear infinite;
}

@keyframes scaleUp {
  0% {
    transform: translate(-50%, -50%) scale(0)
  }
  60%, 100% {
    transform: translate(-50%, -50%) scale(1)
  }
}

@keyframes pulse {
  0%, 60%, 100% {
    transform: scale(1)
  }
  80% {
    transform: scale(1.2)
  }
}


.dot-loader {
  width: 2px;
  height: 2px;
  border-radius: 50%;
  display: block;
  margin: 12px 0 15px 16px;
  position: relative;
  color: var(--primary-alert-color);

  box-sizing: border-box;
  animation: animloader 1s linear infinite alternate;
}

@keyframes animloader {
  0% {
    box-shadow: -15px -2px, -10px 0, -5px -2px;
  }
  33% {
    box-shadow: -15px 0, -10px -2px, -5px 0;
  }
  66% {
    box-shadow: -15px -2px, -10px 0, -5px -2px;
  }
  100% {
    box-shadow: -15px 0, -10px -2px, -5px 0;
  }

}


</style>
