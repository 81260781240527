const enUS = {
    appName: 'Arraial de Flores na Zona Norte',
    login_button_text: {
        mobile: 'Mobile Login',
        email: 'Email Login',
        back: 'Back',
        send: 'Send',
        login: 'Login',
        back_home: 'Back to Home',
        sms_code:'SMS verification code'
    },
    button_text: {
        start_game: 'Start Lottery',
        navigate: "Navigate",
        amap_navigate: "Navigate with Amap",
        gmap_navigate: "Navigate with Google Maps",
        submit:"Submit",
    },
    login_input: {
        email: 'Email Address...',
        phone: 'Phone Number...',
        code: 'Verification Code...',
    },
    pages: {
        home: 'Home',
        map: 'Event Map',
        my_game: 'Prize Draw',
        quiz: 'Questionnaire',
        login: 'Login',
        bag: "Prize Bag",
        my_profile: "My Profile",
        merchant_info: "Business Info",
        mini_game: "Mini Game",
        event_insight: "Events & Installation",
        stamp_page: "E-Stamp",
        activity: "Prize Draw",
        task: "Activity Task Bar",
        lottery_page: "Prize Draw",
        interactive: "Fun Zone",
    },
    tip: {
        opening_map: 'Opening Map',
        loading_game: 'Loading Game'
    },
    request: {
        no_auth: "No Permission",
        network_err: "Network Error",
        need_login: "Please Login First",
        too_many_times: "Too Many Requests, Please Try Later",
        loading: "Requesting",
        send_success: "Send Successful",
        submit_success: "Submission Successful",
        request_fail: "Please Try Again Later",
        login_fail: "Login Failed",
        login_success: "Login Successful",
        active_code_err: "Invalid Verification Code",
    },
    server_error: {
        job_finished: "Task Completed",
        stamp_try_again: "Please Try Again",
        user_not_verify:"Please Verify First"
    },
    server_success: {
        stamp_job_success: "Stamp Successful",
    },
    lottery: {
        no_gift: "No prize yet",
        need_finish_more_task: "Please Complete More Tasks",
        now_lottery: "Lottery In Progress",
        get_gift: "Claim the Prize",
        game_over: "Thank you for participating",
        get_gift_info: "Appointment Information for Prize Collection",
        have_opportunity_a:"You have ",
        have_opportunity_b:" draw(s) left"
    },
    system: {
        loading_data:"Loading",
        finished_job:"Task Completed",
        submit_data:"Submitting",

    },
    system_error:{
        phone_formatter_error:"Phone Number Format Error",
        send_sms_to_limit:"SMS Limit Reached",
        send_sms_fail:"SMS Sending Failed, Please Try Later",
        change_position:"Please Adjust Position and Try Again",
        stamp_later:"Too Fast, Please Try Later",
    },
    stamp:{
        submit:"Stamping"
    },
    bag_page:{
        select_date:"Select Date",
        confirm:"Confirm",
        confirm_button:"Confirm",
        cancel_button:"Cancel",
        confirm_again:"Once submitted, the reservation details cannot be changed. Confirm submission?",
        claim_award:"Claim the prize",
        award_collect_date:"Appointment date",
        award_collect_time:"Appointment time",
        choose_award_collect_date:"Appointment date",
        choose_award_collect_time:"Appointment time",
        award_collect_people:"Name of the recipient",
        appointment_success:"Appointment completed"
    },
    quiz:{
        please_finish_quiz:"Please Complete All Surveys",
        other:"Other"
    },
    task:{
        main_des:"The 5 tasks are as follows:",
        quiz_task_name:"Questionnaire",
        quiz_task_content:"Fill and submit on the event website.",
        stamp_task_name:"Stamp Collection",
        stamp_task_content_a:"Collect electronic stamps from ",
        stamp_task_content_b:"installations",
        stamp_task_content_b_s:"installation",
    },
    event_insight:{
        go_to_place:"Please go to the location to clock in for the task.",
    }
}
export default enUS;
