const zhCN = {
    appName: '花荟北区',
    login_button_text: {
        mobile: '手机登录',
        email: '邮箱登录',
        back: '返回',
        send: '发送',
        login: '登入',
        back_home: '返回首页',
        sms_code:'短讯验证码'
    },
    button_text: {
        start_game: '开始抽奖',
        navigate: "导航",
        amap_navigate: "使用高德地图导航",
        gmap_navigate: "使用Google地图导航",
        submit:"提交",
    },
    login_input: {
        email: '邮箱地址...',
        phone: '手机号码...',
        code: '验证码...',
    },
    pages: {
        home: '首页',
        map: '活动地图',
        my_game: '抽奖游戏',
        quiz: '问卷调查',
        login: '登录',
        bag: "中奖背包",
        my_profile: "我的资料",
        merchant_info: "商户简介",
        mini_game: "小游戏",
        event_insight: "活动及装置信息",
        stamp_page: "电子印章",
        activity: "游戏抽奖",
        task: "活动任务栏",
        lottery_page: "游戏抽奖",
        interactive: "互动内容",

    },
    tip: {
        opening_map: '打开地图中',
        loading_game: '加载游戏中'
    },
    request: {
        no_auth: "无权限",
        network_err: "网络异常",
        need_login: "请先登录",
        too_many_times: "请求太频繁，请稍后",
        loading: "请求中",
        send_success: "发送成功",
        submit_success: "提交成功",
        request_fail: "请稍后再试",
        login_fail: "登录失败",
        login_success: "登录成功",
        active_code_err: "验证码无效",
    },
    server_error: {
        job_finished: "任务已完成",
        stamp_try_again: "请再试一次",
        user_not_verify:"请先进行安全验证"
    },
    server_success: {
        stamp_job_success: "盖章成功",
    },
    lottery: {
        no_gift: "暂时未有奖品",
        need_finish_more_task: "请完成更多任务",
        now_lottery: "抽奖中",
        get_gift: "领取奖品",
        get_gift_info: "预约领奖信息",
        game_over: "多谢参与",
        have_opportunity_a:"您还有",
        have_opportunity_b:"次抽奖机会"
    },
    system: {
        loading_data:"加载中",
        finished_job:"任务已完成",
        submit_data:"提交中",

    },
    system_error:{
        phone_formatter_error:"手机号码格式错误",
        send_sms_to_limit:"发送次数达上限",
        send_sms_fail:"发送失败 请稍后再试",
        change_position:"请调整角度后再试",
        stamp_later:"太快了，请稍后再试",
    },
    stamp:{
        submit:"盖印中"
    },
    bag_page:{
        claim_award:"预约领奖",
        select_date:"选择日期",
        confirm:"确认提交",
        confirm_button:"确认",
        cancel_button:"取消",
        confirm_again:"提交预约成功以后，信息不能改动。确定提交吗？",
        award_collect_date:"预约领奖日期",
        award_collect_time:"预约领奖时间",
        choose_award_collect_date:"选择预约领奖日期",
        choose_award_collect_time:"选择预约领奖时间",
        award_collect_people:"领奖人姓名",
        appointment_success:"预约成功"
    },
    quiz:{
        please_finish_quiz:"请完成全部问卷",
        other:"其他"
    },
    task:{
        main_des:"5个任务分别如下：",
        quiz_task_name:"问卷调查",
        quiz_task_content:"于活动网站填妥并完成提交",
        stamp_task_name:"印章打卡",
        stamp_task_content_a:"收集任意",
        stamp_task_content_b:"个装置的电子印章",
        stamp_task_content_b_s:"个装置的电子印章",
    },
    event_insight:{
        go_to_place:"请前往该地点进行打卡任务",
    }
}
export default zhCN;
