import {createVNode, render} from "vue";
import Component from "./src/index.vue";

interface IOptions {
    label: string;
    toast_type?: "success" | "alert" | "warning" | "error" | "loading";
    duration?: number;
    type: string;
    top: boolean;
    showCover: boolean;
}

const LTTips = {
    closeLTTips(mountNode: HTMLDivElement) {
        if ( document.body.contains(mountNode)) {
            document.body.removeChild(mountNode);
        }
    },
     createLTTips(options: IOptions) {
         let mountNode: HTMLDivElement;
        const duration = options.duration || 10000;
        const app = createVNode(Component, {
            ...options,
            duration,
            show_toast: true,
        });
        const timer = setTimeout(() => {
            if ( document.body.contains(mountNode)) {
                document.body.removeChild(mountNode);
            }
            clearTimeout(timer);
        }, duration);
        mountNode = document.createElement("div");
        render(app, mountNode);
        document.body.appendChild(mountNode);
        return mountNode;
    }
}
export default LTTips;
