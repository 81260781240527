import {createI18n} from "vue-i18n";
import store from "@/store";
import languages from "@/utils/language";
// let language = navigator.language;
// switch (language) {
//     case 'zh-CN':
//         break;
//     case 'zh-TW':
//         language = 'zh-MO';
//         break;
//     case 'zh-HK':
//         language = 'zh-MO';
//         break
//     case 'zh-MO':
//         break
//     case 'en-US':
//         break ;
//     case 'pt-PT':
//         break
//     default:
//         language = 'zh-MO';
// }
// 获取语言设置，若未设置则默认为 "zh-MO"
let language = sessionStorage.getItem('language');
if (!language) {  // 如果 language 为 null 或空字符串
    language = "zh-MO";  // 设置默认语言为 "zh-MO"
    sessionStorage.setItem('language', language);  // 存储默认语言设置
}
store.commit("changeUserLanguage", language);  // 更新 Vuex 中的语言设置

// 配置 Vue I18n
const i18n = createI18n({
    legacy: false,  // 使用 Composition API 模式
    locale: language, // 确保当前语言环境有效
    fallbackLocale: 'en', // 设置备用语言环境
    messages: languages, // 本地化的信息对象
});

export default i18n