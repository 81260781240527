import axios, {AxiosRequestConfig} from 'axios';
import LTTips from "@/utils/LTTips";
import router from "@/router";
import i18n from "@/lang";

export interface Response {
    status: number,
    data: {
        status: number,
        data: object,
        msg: string
    }
}

const axiosRequestConfig: AxiosRequestConfig = {
    headers: {
        "Content-Type": 'application/json;charset=UTF-8',
    },
}
const LetoyService = {
    async authRequest(url: string, params: object, data: object, fallCallBack: () => void, onFinalCallBack: () => void) {
        if (sessionStorage.getItem('token') == null) {
            LTTips.createLTTips({
                label: i18n.global.t("request.need_login"),
                toast_type: "warning",
                duration: 800,
                type: "warning",
                top: true,
                showCover: true
            });
            await router.push("/login");
            return {status: 101};
        }
        const axiosRequestConfigAuth: AxiosRequestConfig = {
            headers: {
                "Content-Type": 'application/json;charset=UTF-8',
                "Authorization": sessionStorage.getItem('token') || ""
            },
        }
        axiosRequestConfigAuth.params = params
        const post = () => {
            return new Promise(resolve => {
                axios.post("/api" + url, data, axiosRequestConfigAuth)
                    .then((response: Response) => {
                        if (response.status === 200) {
                            let responseStatus = response.data.status;
                            switch (responseStatus) {
                                case 6:
                                    LTTips.createLTTips({
                                        label: i18n.global.t("request.no_auth"),
                                        toast_type: "warning",
                                        duration: 800,
                                        type: "warning",
                                        top: true,
                                        showCover: true
                                    });
                                    router.push("/");
                                    break;
                                case 5:
                                    LTTips.createLTTips({
                                        label: i18n.global.t("server_error.request_too_many_times"),
                                        toast_type: "warning",
                                        duration: 800,
                                        type: "warning",
                                        top: true,
                                        showCover: true
                                    });
                                    break;
                            }

                            resolve(response.data);
                        } else {
                            LTTips.createLTTips({
                                label: i18n.global.t("request.network_err"),
                                toast_type: "warning",
                                duration: 800,
                                type: "warning",
                                top: true,
                                showCover: true
                            });
                            resolve(null);
                        }
                    }).catch(fallCallBack).finally(onFinalCallBack);
            });
        };
        return await post();
    },
    async noAuthRequest(url: string, params: object, data: object, fallCallBack: () => void, onFinalCallBack: () => void) {
        const axiosRequestConfigAuth: AxiosRequestConfig = {
            headers: {
                "Content-Type": 'application/json;charset=UTF-8'
            },
        }
        axiosRequestConfigAuth.params = params
        const post = () => {
            const loadingTip = LTTips.createLTTips({
                label: i18n.global.t("request.loading"),
                toast_type: "loading",
                duration: 8000,
                type: "loading",
                top: true,
                showCover: false
            });
            return new Promise(resolve => {
                axios.post("/api" + url, data, axiosRequestConfig)
                    .then((response: Response) => {
                        LTTips.closeLTTips(loadingTip);
                        if (response.status === 200) {
                            let responseStatus = response.data.status;
                            switch (responseStatus) {
                                case 6:
                                    LTTips.createLTTips({
                                        label: i18n.global.t("request.need_login"),
                                        toast_type: "warning",
                                        duration: 800,
                                        type: "warning",
                                        top: true,
                                        showCover: true
                                    });
                                    router.push("/");
                                    break;
                            }
                            resolve(response.data);
                        } else {
                            LTTips.createLTTips({
                                label: i18n.global.t("request.network_err"),
                                toast_type: "warning",
                                duration: 800,
                                type: "warning",
                                top: true,
                                showCover: true
                            });
                            resolve(null);
                        }
                    }).catch(fallCallBack).finally(onFinalCallBack);
            });
        };
        return await post();
    },
    async authNoCheckRequest(url: string, params: object, data: object, fallCallBack: () => void, onFinalCallBack: () => void) {
        const axiosRequestConfigAuth: AxiosRequestConfig = {
            headers: {
                "Content-Type": 'application/json;charset=UTF-8',
                "Authorization": sessionStorage.getItem('token') || ""
            },
        }
        axiosRequestConfigAuth.params = params
        const post = () => {
            return new Promise(resolve => {
                axios.post("/api" + url, data, axiosRequestConfigAuth)
                    .then((response: Response) => {
                        if (response.status === 200) {
                            let responseStatus = response.data.status;
                            switch (responseStatus) {
                                case 6:
                                    LTTips.createLTTips({
                                        label: i18n.global.t("request.no_auth"),
                                        toast_type: "warning",
                                        duration: 800,
                                        type: "warning",
                                        top: true,
                                        showCover: true
                                    });
                                    router.push("/");
                                    break;
                                case 5:
                                    LTTips.createLTTips({
                                        label: i18n.global.t("request.too_many_times"),
                                        toast_type: "warning",
                                        duration: 800,
                                        type: "warning",
                                        top: true,
                                        showCover: true
                                    });
                                    break;
                            }

                            resolve(response.data);
                        } else {
                            LTTips.createLTTips({
                                label: i18n.global.t("request.network_err"),
                                toast_type: "warning",
                                duration: 800,
                                type: "warning",
                                top: true,
                                showCover: true
                            });
                            resolve(null);
                        }
                    }).catch(fallCallBack).finally(onFinalCallBack);
            });
        };
        return await post();
    },
};
export default LetoyService;
