import {computed, createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {createI18n} from 'vue-i18n'
import VueViewer from 'v-viewer'
import 'viewerjs/dist/viewer.css';
import TDesign from 'tdesign-mobile-vue';
// 引入组件库的少量全局样式变量
import 'tdesign-mobile-vue/es/style/index.css';
import index from "@/utils/language";
import i18n from "@/lang";

createApp(App).use(TDesign).use(VueViewer, {
    defaultOptions: {
        toolbar: false,
        navbar: false,
        title:false
    }
}).use(i18n).use(store).use(router).mount('#app')
