import zhCN from "@/utils/language/zhCN";
import zhMO from "@/utils/language/zhMO";
import pt from "@/utils/language/pt";
import enUS from "@/utils/language/enUS";

const index = {
    'zh-CN': zhCN,
    "en-US": enUS,
    "pt-PT": pt,
    "zh-MO": zhMO,
}
export default index;