const pt = {
    appName: 'Arraial de Flores na Zona Norte',
    login_button_text: {
        mobile: 'Login com Celular',
        email: 'Login com Email',
        back: 'Voltar',
        send: 'Enviar',
        login: 'Login',
        back_home: 'Voltar para a Página Inicial',
        sms_code:'Código de verificação por SMS'
    },
    button_text: {
        start_game: 'Iniciar Sorteio',
        navigate: "Navegar",
        amap_navigate: "Navegar com Amap",
        gmap_navigate: "Navegar com Google Maps",
        submit:"Submeter",
    },
    login_input: {
        email: 'Endereço de Email...',
        phone: 'Número de Celular...',
        code: 'Código de Verificação...',
    },
    pages: {
        home: 'Página inicial',
        map: 'Mapa do Evento',
        my_game: 'Jogo e sorteio',
        quiz: 'Questionário',
        login: 'Login',
        bag: "Bolsa de Prêmios",
        my_profile: "Meu Perfil",
        merchant_info: "Breve introdução das lojas aderentes",
        mini_game: "Mini Jogo",
        event_insight: "Informações de Eventos e Dispositivos",
        stamp_page: "Selo Eletrônico",
        activity: "Jogo e sorteio",
        task: "Barra de Tarefas do Evento",
        lottery_page: "Jogo e sorteio",
        interactive: "Conteúdo interactivo",
    },
    tip: {
        opening_map: 'Abrindo Mapa',
        loading_game: 'Carregando Jogo'
    },
    request: {
        no_auth: "Sem Permissão",
        network_err: "Erro de Rede",
        need_login: "Por favor, Faça Login Primeiro",
        too_many_times: "Muitos Pedidos, Tente Mais Tarde",
        loading: "Solicitando",
        send_success: "Envio Bem-sucedido",
        submit_success: "Submissão Bem-sucedida",
        request_fail: "Tente Novamente Mais Tarde",
        login_fail: "Falha no Login",
        login_success: "Login Bem-sucedido",
        active_code_err: "Código de Verificação Inválido",
    },
    server_error: {
        job_finished: "Tarefa Concluída",
        stamp_try_again: "Tente Novamente",
        user_not_verify:"Por favor, Verifique-se Primeiro"
    },
    server_success: {
        stamp_job_success: "Selo Bem-sucedido",
    },
    lottery: {
        no_gift: "Para já não há nenhum prémio",
        need_finish_more_task: "Por favor, Complete Mais Tarefas",
        now_lottery: "Sorteio em Andamento",
        get_gift: "Levantamento do prémio",
        get_gift_info: "Informações para Coleta do Prêmio",
        game_over: "Obrigado pela participação",
        have_opportunity_a:"Tem",
        have_opportunity_b:" oportunidade(s) de sorteio. Obrigado pela sua participação"
    },
    system: {
        loading_data:"Carregando",
        finished_job:"Tarefa Concluída",
        submit_data:"Submetendo",

    },
    system_error:{
        phone_formatter_error:"Erro de Formato de Número de Celular",
        send_sms_to_limit:"Limite de SMS Atingido",
        send_sms_fail:"Falha ao Enviar SMS, Tente Mais Tarde",
        change_position:"Ajuste a Posição e Tente Novamente",
        stamp_later:"Muito Rápido, Tente Mais Tarde",
    },
    stamp:{
        submit:"Carimbando"
    },
    bag_page:{
        select_date:"Select Date",
        claim_award:"Levantamento do prémio",
        confirm:"Confirmar",
        confirm_button:"Confirmar",
        cancel_button:"Cancelar",
        confirm_again:"Uma vez submetido, os detalhes da reserva não podem ser alterados. Confirmar envio?",

        award_collect_date:"Escolha da data da marcação para levantamento do prémio",
        award_collect_time:"Escolha da hora da marcação para levantamento do prémio",
        choose_award_collect_date:"Escolha da data da marcação para levantamento do prémio",
        choose_award_collect_time:"Escolha da hora da marcação para levantamento do prémio",
        award_collect_people:"Nome do premiado",
        appointment_success:"Marcação bem-sucedida"
    },
    quiz:{
        please_finish_quiz:"Por favor, Complete Todas as Pesquisas",
        other:"Outros"
    },
    task:{
        main_des:"As cinco tarefas incluem:",
        quiz_task_name:"Questionário",
        quiz_task_content:"Preencher e entregar o mesmo no Website da Actividade",
        stamp_task_name:"Check-in com carimbos",
        stamp_task_content_a:"Recolher",
        stamp_task_content_b:"os carimbos electrónicos colocados nos dispositivos",
        stamp_task_content_b_s:"os carimbos electrónicos colocados nos dispositivos",
    },
    event_insight:{
        go_to_place:"Por favor, vá até o local para registrar o ponto na tarefa.",
    }
}
export default pt;
