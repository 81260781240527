import {createStore} from 'vuex'

export default createStore({
    state: {

        username: "体验用户",
        token: "",
        roles: 0,
        nickname: "默认用户",
        phoneNumber: "",
        uid: "",
        active:false,
        avatar:"",

        windowResizeEvent: {},
        sidebarVisible: false,
        userLanguage: 'zh-MO',
        deviceType: '',
    },
    getters: {
        getUsername(state) {
            return state.username;
        },
        getToken(state) {
            return state.token;
        },
        getRoles(state) {
            return state.roles;
        },
        getNickname(state) {
            return state.nickname;
        },
        getPhoneNumber(state) {
            return state.phoneNumber;
        },
        getUid(state) {
            return state.uid;
        },
        getActive(state) {
            return state.active;
        },
        getAvatar(state) {
            return state.avatar;
        },
        getWindowResizeEvent(state) {
            return state.windowResizeEvent;
        },
        getSidebarVisible(state) {
            return state.sidebarVisible;
        },
        getUserLanguage(state) {
            return state.userLanguage;
        },
        getDeviceType(state) {
            return state.deviceType;
        },
    },
    mutations: {
        changeUsername(state, username) {
            state.username = username;
        },
        changeToken(state, token) {
            state.token = token;
        },
        changeRoles(state, roles) {
            state.roles = roles;
        },
        changeNickname(state, nickname) {
            state.nickname = nickname;
        },
        changePhoneNumber(state, phoneNumber) {
            state.phoneNumber = phoneNumber;
        },
        changeUid(state, uid) {
            state.uid = uid;
        },
        changeActive(state, active) {
            state.active = active;
        },
        changeAvatar(state, avatar) {
            state.avatar = avatar;
        },
        changeWindowResizeEvent(state, windowResizeEvent) {
            state.windowResizeEvent = windowResizeEvent;
        },
        changeSidebarVisible(state, sidebarVisible) {
            state.sidebarVisible = sidebarVisible;
        },
        changeUserLanguage(state, userLanguage) {
            sessionStorage.setItem("language", userLanguage);
            state.userLanguage = userLanguage;
        },
        changeDeviceType(state, deviceType) {
            state.deviceType = deviceType;
        },
    },
    actions: {
        changeUsername(context, username) {
            context.commit('changeUsername', username)
        },
        changeToken(context, token) {
            context.commit('changeToken', token)
        },
        changeRoles(context, roles) {
            context.commit('changeRoles', roles)
        },
        changeNickname(context, nickname) {
            context.commit('changeNickname', nickname)
        },
        changePhoneNumber(context, phoneNumber) {
            context.commit('changePhoneNumber', phoneNumber)
        },
        changeUid(context, uid) {
            context.commit('changeUid', uid)
        },
        changeActive(context, active) {
            context.commit('changeActive', active)
        },
        changeAvatar(context, avatar) {
            context.commit('changeAvatar', avatar)
        },
        changeWindowResizeEvent(context, windowResizeEvent) {
            context.commit('changeWindowResizeEvent', windowResizeEvent)
        },
        changeSidebarVisible(context, sidebarVisible) {
            context.commit('changeSidebarVisible', sidebarVisible)
        },
        changeUserLanguage(context, userLanguage) {
            context.commit('changeUserLanguage', userLanguage)
        },
        changeDeviceType(context, deviceType) {
            context.commit('changeDeviceType', deviceType)
        },
    },
    modules: {}
})