<template>
  <router-view/>
</template>
<script lang="ts" setup>
import {onMounted, onUnmounted} from 'vue';
import {useStore} from 'vuex';

const store = useStore();

onMounted(() => {
  window.addEventListener('resize', handleResize);
  loadFacebookSDK();
  store.commit('changeDeviceType', getDeviceType())
  loadData();
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
  // 清理fbAsyncInit，避免重复或不必要的初始化
  window.fbAsyncInit = () => {
  };
});

function handleResize(e: Event) {
  store.commit("changeWindowResizeEvent", e);
}

function getDeviceType() {
  let agent = navigator.userAgent.toLowerCase();
  if (agent.indexOf("iphone") != -1) {
    return "ios";
  }
  if (agent.indexOf("macintosh") != -1) {
    return "mac";
  }
  if (agent.indexOf("android") != -1) {
    return "android";
  }
  if (agent.indexOf("harmonyos") != -1) {
    return "harmonyos";
  }
}

function loadFacebookSDK() {
  if (document.getElementById('facebook-jssdk')) return;

  const js = document.createElement('script');
  js.id = 'facebook-jssdk';
  js.src = "https://connect.facebook.net/en_US/sdk.js";
  const fjs = document.getElementsByTagName('script')[0];

  if (!fjs.parentNode) return;
  fjs.parentNode.insertBefore(js, fjs);

  // 定义全局fbAsyncInit初始化函数
  window.fbAsyncInit = function () {
    window.FB.init({
      appId: '393986261841574', // 替换为您的Facebook应用ID
      cookie: true,
      xfbml: true,
      version: 'v19.0' // 使用的Facebook API版本
    });
    window.FB.AppEvents.logPageView();
  };
}

function loadData() {
  store.commit("changeNickname", sessionStorage.getItem('nickname'));
  store.commit("changeUid", sessionStorage.getItem('uid'));
  store.commit("changePhoneNumber", sessionStorage.getItem('phoneNumber'));
  store.commit("changeRoles", sessionStorage.getItem('roles'));
  store.commit("changeToken", sessionStorage.getItem('token'));
  store.commit("changeUsername", sessionStorage.getItem('username'));
  store.commit("changeActive", sessionStorage.getItem('active'));
  store.commit("changeAvatar", sessionStorage.getItem('avatar'));

}
</script>

<style src="@/assets/css/index.css"></style>
<style src="@/assets/css/fonts.css"></style>
