const zhMO = {
    appName: '花薈北區',
    login_button_text: {
        mobile: '手機登錄',
        email: '電郵登錄',
        back: '返回',
        send: '發送',
        login: '登入',
        back_home: '返回主頁',
        sms_code:'短訊驗證碼'
    },
    button_text: {
        start_game: '開始抽獎',
        navigate: "導航",
        amap_navigate: "使用高德地圖導航",
        gmap_navigate: "使用Google地圖導航",
        submit:"提交",
    },
    login_input: {
        email: '電郵地址...',
        phone: '手機號碼...',
        code: '驗證碼...',
    },
    pages: {
        home: '首頁',
        map: '活動地圖',
        my_game: '抽獎遊戲',
        quiz: '問卷調查',
        login: '登錄',
        bag: "中獎背包",
        my_profile: "我的資料",
        merchant_info: "商戶簡介",
        mini_game: "小遊戲",
        event_insight: "活動及裝置資訊",
        stamp_page: "電子印章",
        activity: "遊戲抽獎",
        task: "活動任務欄",
        lottery_page: "遊戲抽獎",
        interactive: "互動內容",
    },
    tip: {
        opening_map: '打開地圖中',
        loading_game: '加載遊戲中'
    },
    request: {
        no_auth: "無權限",
        network_err: "網絡異常",
        need_login: "請先登錄",
        too_many_times: "請求太頻繁，請稍後",
        loading: "請求中",
        send_success: "發送成功",
        submit_success: "提交成功",
        request_fail: "請稍後再試",
        login_fail: "登錄失敗",
        login_success: "登錄成功",
        active_code_err: "驗證碼無效",
    },
    server_error: {
        job_finished: "任務已完成",
        stamp_try_again: "請再試一次",
        user_not_verify:"請先進行安全驗證",
        request_too_many_times:"訪問頻繁，稍後再試"
    },
    server_success: {
        stamp_job_success: "蓋章成功",
    },
    lottery: {
        no_gift: "暫時未有獎品",
        need_finish_more_task: "請完成更多任務",
        now_lottery: "抽獎中",
        get_gift: "領取獎品",
        get_gift_info: "預約領獎資訊",
        game_over: "多謝參與",
        have_opportunity_a:"您還有",
        have_opportunity_b:"次抽獎機會"
    },
    system: {
        loading_data:"加載中",
        finished_job:"任務已完成",
        submit_data:"提交中",

    },
    system_error:{
        phone_formatter_error:"手機號碼格式錯誤",
        send_sms_to_limit:"發送次數達上限",
        send_sms_fail:"發送失敗 請稍後再試",
        change_position:"請調整角度後再試",
        stamp_later:"太快了，請稍後再試",
    },
    stamp:{
        submit:"蓋印中"
    },
    bag_page:{
        claim_award:"預約領獎",
        select_date:"選擇日期",
        confirm:"確認提交",
        confirm_button:"確認",
        cancel_button:"取消",
        confirm_again:"提交預約成功以後，信息不能改動。確定提交嗎？",
        award_collect_date:"預約領獎日期",
        award_collect_time:"預約領獎時間",
        choose_award_collect_date:"選擇預約領獎日期",
        choose_award_collect_time:"選擇預約領獎時間",
        award_collect_people:"領獎人姓名",
        appointment_success:"預約成功"
    },
    quiz:{
        please_finish_quiz:"請完成全部問卷",
        other:"其他"
    },
    task:{
        main_des:"5個任務分別如下：",
        quiz_task_name:"問卷調查",
        quiz_task_content:"於活動網站填妥並完成提交",
        stamp_task_name:"印章打卡",
        stamp_task_content_a:"收集任意",
        stamp_task_content_b:"個裝置的電子印章",
        stamp_task_content_b_s:"個裝置的電子印章",
    },
    event_insight:{
        go_to_place:"請前往該地點進行打卡任務",
    }
}
export default zhMO;